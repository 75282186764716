import { WishlistElementHeadless } from 'https://cdn.jsdelivr.net/npm/@appmate/wishlist@4.29.2/wishlist-element-headless.js';

class WishlistButtonCart extends WishlistElementHeadless {
  getStateConfig() {
    return {
      productInfo: true,
    };
  }

  getEventConfig() {
    return {
      click: this.handleClick,
    };
  }

  async handleClick() {
    try {
      if (!this.productInfo.inWishlist) {
        await this.app.addWishlistItem(this.productInfo);
      }

      const cartRemoveButton = this.host.closest('.cartItem').querySelector('.cartItem__trash');
      cartRemoveButton.click();
    } catch (error) {
      console.warn(error);
    }
  }

  updated() {
    const textElement = this.host.querySelector('.wk-text');
    textElement.innerText = this.getText();
  }

  getText() {
    return 'Move to Wishlist';
  }
}

window.headlessElements.define('wishlist-button-cart', WishlistButtonCart);
